import { gql } from '@apollo/client'
import API from '../API'

export const generateSignedUploadRequestMutation = gql`
  mutation generateSignedUploadRequest($resourceType: String) {
    generateSignedUploadRequest(resourceType: $resourceType) {
      api_url
      signature
      api_key
      timestamp
    }
  }
`
const uploadImageToCloudinary = (
  file: any,
  signedUploadRequestVariables = null,
  onProgress: (progress: number) => void
) => {
  return new Promise(async (resolve, reject) => {
    const variables: any = signedUploadRequestVariables ?? {
      caption: file.name
    }

    const uploadRequestResult = await API.mutate({
      mutation: generateSignedUploadRequestMutation,
      variables,
      fetchPolicy: 'no-cache'
    })

    const signedUploadRequest =
      uploadRequestResult.data.generateSignedUploadRequest

    const xhr = new XMLHttpRequest()
    const form = new FormData()

    xhr.open('POST', signedUploadRequest.api_url, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    xhr.upload.addEventListener('progress', e => {
      onProgress(Math.round((e.loaded / e.total) * 100))
    })

    xhr.onreadystatechange = () => {
      console.log(`XHR state ${xhr.readyState}, status ${xhr.status}`)

      if (xhr.readyState !== 4) return

      if (xhr.status === 200) {
        resolve({ ...JSON.parse(xhr.responseText), filename: file.name })
      } else {
        reject(xhr.statusText)
      }
    }

    form.append('timestamp', signedUploadRequest.timestamp)
    form.append('signature', signedUploadRequest.signature)
    form.append('api_key', signedUploadRequest.api_key)
    form.append('file', file)

    xhr.send(form)
  })
}

export default uploadImageToCloudinary
