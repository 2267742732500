import { useCallback, useState } from 'react'
// Hooks/Utils
import useToast from '../../../hooks/useToast'
import useSetCompanyLogo from './useSetCompanyLogo'
import useDeleteCompanyLogo from './useDeleteCompanyLogo'
import useTranslation from '../../../hooks/useTranslation'
import uploadImageToCloudinary from '../../../utils/uploadImageToCloudinary'
// Types
import { Company } from '../../../types'
import { MediaI, CustomCrop } from '../components/ImageViewer'

interface CompanyLogoEditResult {
  // Loading indicators
  isDeleting: boolean
  isUploading: boolean
  isUploadCompleted: boolean
  setCompanyLogoLoading: boolean
  cloudinaryProgress: number
  // Events
  editCompanyLogo: (image: MediaI, crop?: CustomCrop) => void
  uploadCompanyLogo: (image: MediaI, crop?: CustomCrop) => void
  removeCompanyLogo: (onComplete?: () => void) => void
}

const formatCropping = cropping => {
  if (!cropping) return
  return JSON.stringify({
    ...cropping,
    shape: 'rect',
    unit: cropping.unit || 'px'
  })
}

const useCompanyLogoEdit = (company: Company): CompanyLogoEditResult => {
  const [isUploading, setIsUploading] = useState(false)
  const [isUploadCompleted, setIsUploadCompleted] = useState(false)
  const [cloudinaryProgress, setCloudinaryProgress] = useState(0)

  const companyId = company?.id

  const { t } = useTranslation()
  const { setToastErrorMessage } = useToast()

  // Mutations
  const { setCompanyLogo, loading: setCompanyLogoLoading } = useSetCompanyLogo()
  const {
    deleteCompanyLogo,
    loading: deleteCompanyLogoLoading
  } = useDeleteCompanyLogo(companyId)

  const uploadCompanyLogo = useCallback(
    async (image: MediaI, crop?: CustomCrop) => {
      setIsUploading(true)
      setIsUploadCompleted(false)
      setCloudinaryProgress(0)
      try {
        const signedUploadRequestVariables = { resourceType: 'image' }

        const result: any = await uploadImageToCloudinary(
          image.file,
          signedUploadRequestVariables as any,
          progress => {
            setCloudinaryProgress(progress)
          }
        )

        await setCompanyLogo({
          companyId,
          cropping: formatCropping(crop),
          cloudinaryImage: {
            cloudinaryId: result.public_id
          }
        })
        setIsUploadCompleted(true)
        await delay(1000)
      } catch (uploadCompanyLogoError) {
        console.log({ uploadCompanyLogoError })
        setToastErrorMessage(
          t('directoryProfile:companyLogoUploader:unknownLoadImageError')
        )
      } finally {
        setIsUploading(false)
      }
    },
    [companyId]
  )

  const editCompanyLogo = useCallback(
    async (image: MediaI, crop?: CustomCrop) => {
      setIsUploading(true)
      setIsUploadCompleted(false)
      setCloudinaryProgress(75)
      try {
        if (!image?.cloudinaryId) throw new Error('No image provided')

        await setCompanyLogo({
          companyId,
          cropping: formatCropping(crop),
          cloudinaryImage: {
            cloudinaryId: image.cloudinaryId
          }
        })
        setIsUploadCompleted(true)
        await delay(1000)
      } catch (editCompanyLogoError) {
        console.log({ editCompanyLogoError })
        setToastErrorMessage(
          t('directoryProfile:companyLogoUploader:unknownLoadImageError')
        )
      } finally {
        setIsUploading(false)
      }
    },
    []
  )

  const removeCompanyLogo = useCallback(async () => {
    try {
      await deleteCompanyLogo({ companyId })
    } catch (error) {
      console.log({ error })
    }
  }, [companyId])

  return {
    isDeleting: deleteCompanyLogoLoading,
    isUploading,
    isUploadCompleted,
    setCompanyLogoLoading,
    cloudinaryProgress,
    editCompanyLogo,
    uploadCompanyLogo,
    removeCompanyLogo
  }
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export default useCompanyLogoEdit
