import React, { FC, useMemo, useState } from 'react'
import styled from 'styled-components/native'
// Hooks
import useToast from '../../../hooks/useToast'
import useTranslation from '../../../hooks/useTranslation'
import useCompanyLogoEdit from '../hooks/useCompanyLogoEdit'
// Components
import CompanyLogo, { acceptSignatures } from './CompanyLogo'
import ImageViewer, { MediaI, CustomCrop } from './ImageViewer'
import { Title } from './common/SharedStyledComponents'
// Types
import { Company } from '../../../types'

const MAX_FILE_SIZE_MB = 4
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1000000

// Styled components
const Container = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const PicturesContainer = styled.View`
  ${({ theme: { sizes } }) => `
    margin-top: ${sizes[3]}px;
  `}
`
// Interface/Types
interface CompanyLogoUploaderProps {
  company: Company
}

const CompanyLogoUploader: FC<CompanyLogoUploaderProps> = ({ company }) => {
  const { t } = useTranslation()
  const {
    isDeleting,
    isUploading,
    isUploadCompleted,
    cloudinaryProgress,
    editCompanyLogo,
    uploadCompanyLogo,
    removeCompanyLogo
  } = useCompanyLogoEdit(company)
  const {
    setToastMessage,
    setToastWarningMessage,
    setToastErrorMessage
  } = useToast()
  const [imageToView, setImageToView] = useState<MediaI | null>(null)

  // Handlers
  const onLoad = async file => {
    try {
      const media: MediaI = {
        file,
        uri: URL.createObjectURL(file)
      }

      if ((file?.size ?? 0) >= MAX_FILE_SIZE_BYTES) {
        setToastWarningMessage(
          t('directoryProfile:companyLogoUploader:exceedFileSize', {
            size: MAX_FILE_SIZE_MB
          })
        )
        return
      }

      setImageToView(media)
    } catch (onUploadImageError) {
      setToastErrorMessage(
        onUploadImageError ||
          t('directoryProfile:companyLogoUploader:unknownLoadImageError')
      )
    }
  }

  const onRemove = () => {
    removeCompanyLogo()
  }

  const onCancel = () => {
    setImageToView(null)
  }
  const onUpload = async (crop?: CustomCrop, isEditing?: boolean) => {
    if (!imageToView) return

    if (isEditing) {
      await editCompanyLogo(imageToView, crop)
    } else {
      await uploadCompanyLogo(imageToView, crop)
    }

    setImageToView(null)
    setToastMessage(t('directoryProfile:companyLogoUploader:uploadSuccess'))
  }

  const onEdit = () => {
    const fileToEdit = {
      cloudinaryId: companyLogo?.cloudinaryId
    }
    setImageToView(fileToEdit)
  }

  const onFilesRejected = fileRejections => {
    if (fileRejections.length === 0) return
    setToastWarningMessage(
      t('directoryProfile:companyLogoUploader:invalidImage', {
        types: acceptSignatures.join(', ')
      })
    )
  }

  const companyLogo = useMemo(
    () =>
      company?.companyProfile?.logo
        ? ({
            ...company?.companyProfile?.logo,
            logoCropping: company?.companyProfile?.logoCropping || null
          } as any)
        : undefined,
    [company?.companyProfile]
  )

  if (!company?.canUpdateCompanyLogo) return null

  return (
    <Container>
      <Title>{t('directoryProfile:companyLogoUploader:title')}</Title>
      <PicturesContainer>
        <CompanyLogo
          onEdit={onEdit}
          onLoad={onLoad}
          onRemove={onRemove}
          logo={companyLogo}
          onDropRejected={onFilesRejected}
          loading={isDeleting || isUploading}
          canUpload={!isDeleting && !isUploading}
          canRemove={!!companyLogo && !isDeleting}
        />
      </PicturesContainer>
      {imageToView ? (
        <ImageViewer
          media={imageToView}
          onCancel={onCancel}
          onSave={onUpload}
          isUploading={isUploading}
          isUploadCompleted={isUploadCompleted}
          cloudinaryProgress={cloudinaryProgress}
        />
      ) : null}
    </Container>
  )
}

export default CompanyLogoUploader
